const TermsAndCoditions  = () => {
    
return (
    <div className="bg-gray-100">
      <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold mb-4">Terms and Conditions of Placeti</h1>
        <p className="mb-4">Welcome to Placeti. By using this application, you agree to the terms and conditions set forth below.</p>
        <h2 className="text-2xl font-bold mb-4">Users</h2>
        <p className="mb-4">Users can create an account on Placeti to access the services offered by the application. The personal information that users provide will be treated in accordance with our privacy policy.</p>
        <h2 className="text-2xl font-bold mb-4">Image Upload</h2>
        <p className="mb-4">Users can upload images, including a profile picture. When uploading images, users agree not to violate intellectual property or copyright rights, and not to post inappropriate or illegal content. The user is solely responsible for the images they upload.</p>
        <h2 className="text-2xl font-bold mb-4">Comments and Suggestions</h2>
        <p className="mb-4">We appreciate the comments and suggestions that users want to share with us. By doing so, users grant Placeti the right to use such comments and suggestions without compensation or recognition.</p>
        <h2 className="text-2xl font-bold mb-4">Contact</h2>
        <p className="mb-4">Users can contact us through our email address placetiapp@gmail.com in case of any questions or inquiries related to the terms and conditions of the application.</p>
        <h2 className="text-2xl font-bold mb-4">Modifications</h2>
        <p className="mb-4">Placeti reserves the right to modify the terms and conditions at any time. Users will be notified of such modifications through the application.</p>
        <h2 className="text-2xl font-bold mb-4">Acceptance</h2>
        <p className="mb-4">By using Placeti, the user accepts the terms and conditions set forth in this document.</p>
        <p className="mt-8">Thank you for trusting Placeti.</p>
      </div>
    </div>
  );
}

export default TermsAndCoditions