const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 p-8">
      <div className="max-w-screen-lg mx-auto bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold mb-4">Placeti Privacy Policy</h1>
        <p>
          This Privacy Policy describes how collects, uses, and shares users' information of the
          Placeti mobile application ("Placeti" or "the Application").
        </p>
        <p>Information collected:</p>
        <ul className="list-disc ml-8">
          <li>Email address</li>
          <li>Phone number</li>
          <li>GPS location</li>
          <li>Photos from the gallery</li>
        </ul>
        <p>
          The information is collected through the user's interaction with the
          application.
        </p>
        <p>
          Additionally, non-personal information may be collected through the
          use of tracking technologies such as cookies and web beacons. These
          technologies collect information about the use of the application,
          including the frequency and duration of visits, pages visited, and
          other statistical data.
        </p>
        <p>Use of information:</p>
        <ul className="list-disc ml-8">
          <li>Provide application services and functionalities</li>
          <li>
            Improve the quality of the application and develop new
            functionalities
          </li>
          <li>
            Communicate with users about the application and provide technical
            support
          </li>
          <li>Personalize advertising displayed to users</li>
          <li>Comply with applicable laws and regulations</li>
        </ul>
        <p>
          Non-personal information collected through tracking technologies is
          used for statistical purposes and to improve the quality and
          functionality of the application.
        </p>
        <p>Sharing information:</p>
        <ul className="list-disc ml-8">
          <li>To comply with applicable laws and regulations</li>
          <li>
            To protect the rights, property or safety of Placeti or its users
          </li>
          <li>
            If the application is acquired by a third party, in which case
            users' personal information may be transferred as part of the
            application's assets
          </li>
        </ul>
        <p>
          Placeti uses Google Ads and Google Analytics for advertising and
          application analysis. Google Ads and Google Analytics may collect
          information from the application's users through the use of cookies
          and similar technologies. For more information on how Google Ads and
          Google Analytics collect and use information, please refer to Google's
          privacy policies.
        </p>
        <p>
          The application may display third-party advertising. These advertisers
          may collect information from the application's users through the use
          of cookies and similar technologies. Users can opt-out of receiving
          personalized advertising by adjusting their device settings.
        </p>
        <p>Changes to the Privacy Policy:</p>
        <p>
          This Privacy Policy may change at any time. Users will be notified of
          changes through an application update or via email.
        </p>
        <p>Questions and comments:</p>
        <p>
          If you have any questions or comments about this Privacy Policy,
          please contact us at placetiapp@gmail.com.
        </p>
      </div>
      <p>Last Update: 02/21/2023</p>
    </div>
  );
};

export default PrivacyPolicy;
