
import './App.css';



import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Home from './dashboard/Home';

import PrivacyPolicy from './legal/PrivacyPolicy';
import TermsAndCoditions from './legal/TermsAndCoditions';
/*
import Create from './dashboard/Create';
import Edit from './dashboard/Edit';
import Show from './dashboard/Show';
import Login from './auth/Login';
import Places from './places/home';
import Dashboard from './dashboard/Dashboard';*/
function App() {


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/terms-conditions' element={<TermsAndCoditions />} />
          {/*}
      <Route exact path='/login' element={<Login/>}/>
      <Route exact path='/places' element={<Places/>}/>
      <Route exact path='/dashboard' element={<Dashboard/>}/>
      <Route exact path='/show' element={<Show/>}/>
      <Route exact path='/create' element={<Create/>}/>
          <Route exact path='/edit/:id' element={<Edit />} />*/}
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
